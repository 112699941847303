@import 'reset';

@font-face {
  font-family: 'Bryant Pro';
  src: url('/assets/fonts/BryantPro-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bryant Pro';
  src: url('/assets/fonts/BryantPro-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bryant Pro';
  src: url('/assets/fonts/BryantPro-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bryant Pro Regular';
  src: url('/assets/fonts/BryantPro-RegularItalic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Bryant Pro';
  src: url('/assets/fonts/BryantPro-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Bryant Pro';
  src: url('/assets/fonts/BryantPro-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}
