#root {
  height: 100%;
}

html {
  height: 100%;
  box-sizing: border-box;
  scroll-behavior: smooth;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
  display: flex;
  flex-direction: column;

  *,
  &::before,
  &::after {
    box-sizing: inherit;
    -webkit-overflow-scrolling: touch;
  }
}

body {
  min-width: 360px;
  margin: 0;
  flex: 1 0 auto;
  color: #3a3331;
  font-family: 'Bryant Pro', 'Arial', sans-serif;
  font-size: 1rem;
  line-height: 1.5;
}

a {
  cursor: pointer;
  text-decoration: none;
  color: inherit;

  &[disabled] {
    pointer-events: none;
  }
}

p {
  margin: 0;
}

p a {
  color: #f26c40;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

svg {
  fill: currentColor;
}

sup {
  font-size: 8px;
}

button,
input,
optgroup,
select,
textarea {
  font-family: inherit !important;
  font-size: 1rem;
}

input,
textarea,
select {
  &:-webkit-autofill,
  &:-webkit-autofill,
  &:-webkit-autofill {
    color: #3a3331;
    -webkit-text-fill-color: #3a3331;
    transition: background-color 999999s;

    &:hover,
    &:focus {
      color: #3a3331;
      -webkit-text-fill-color: #3a3331;
    }
  }
}

input[type='file'] {
  display: none;
}

// Changing selected text's color
::selection {
  background: rgb(74 194 206 / 0.3);
}

::-moz-selection {
  background: rgb(74 194 206 / 0.3);
}

// Disabling blue highlight when Touch/Press object with rule 'cursor: pointer'
* {
  -webkit-tap-highlight-color: transparent;
}
